import React from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/navbar/index.jsx";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import buysell from "../../assets/images/buysell.jpg";
import buysell1 from "../../assets/images/buysell1.jpg";
import "./FundRaise.css";
import Container from "@mui/material/Container";
import sellbuy from "../../assets/images/sellbuy.jpg";
import connectbuysell from "../../assets/images/connectbuysell.webp";
import { Grid } from "@mui/material";

export default function BuySell() {
  return (
    <>
      <Navbar />
      <div className="imagetopbuysell">
        <img
          src={connectbuysell}
          alt="articalimage"
          style={{ width: "100%" }}
        />
      </div>
      <div className="buysellbox">
        <h1>Connecting Buyers and Sellers with Confidence</h1>
        <p>
          Are you looking to buy or sell a business? AQT provides comprehensive
          services that facilitate smooth, profitable transactions. We have a
          vast network of buyers and sellers across multiple industries,
          ensuring that you find the right match quickly and efficiently.
        </p>
      </div>
      {/* <div className="bodybuyselltop"> */}
      <Container>
        <div className="bodybuysell">
          <Grid
            style={{ marginTop: "12px" }}
            container
            spacing={2}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={4} sm={8} md={4}>
              <div className="buysellcard">
                <Card sx={{ maxWidth: 345 }}>
                  <CardActionArea>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Due Diligence
                      </Typography>
                      <Typography variant="body2">
                        Our team conducts thorough due diligence on every
                        business to ensure both buyers and sellers are making
                        informed decisions.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            </Grid>
            <Grid item xs={4} sm={8} md={4}>
              <div className="buysellcard">
                <Card sx={{ maxWidth: 345 }}>
                  <CardActionArea>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Business Valuations
                      </Typography>
                      <Typography variant="body2">
                        We offer detailed valuations to ensure that businesses
                        are priced correctly, creating fair deals for both
                        sides.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            </Grid>
            <Grid item xs={4} sm={8} md={4}>
              <div className="buysellcard">
                <Card sx={{ maxWidth: 345 }}>
                  <CardActionArea>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Negotiation Support
                      </Typography>
                      <Typography variant="body2">
                        AQT provides full support during negotiations, ensuring
                        that both parties achieve their desired outcomes.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      {/* <img src={buysell}  alt="articalimage" /> */}
      {/* </div> */}

      <Footer />
    </>
  );
}
