// import * as React from "react";
// import { styled } from "@mui/material/styles";
// import Grid from "@mui/material/Unstable_Grid2";
// import Paper from "@mui/material/Paper";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
// import { Link } from "react-router-dom";
// import refresh from "./refresh.png";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import IT from "../../../assets/images/IT.jpg";
// import Finance from "../../../assets/images/Finance.jpg";
// import Health from "../../../assets/images/Health.jpg";
// import Sales from "../../../assets/images/Sales.jpg";
// import Media from "../../../assets/images/Media.jpg";
// import Marketing from "../../../assets/images/Marketing.jpg";
// import Stocks from "../../../assets/images/Stocks.jpg";
// import Data from "../../../assets/images/Data.jpg";
// import Buisness from "../../../assets/images/Buisness.jpg";
// import Agriculture from "../../../assets/images/Agriculture.png";
// import AcquisitionFunding from "../../../assets/images/carousel_one/Acquisition Funding .png";
// import AngelInvestment from "../../../assets/images/carousel_one/Angel Investment.png";
// import CorporateFinance from "../../../assets/images/carousel_one/Corporate Finance.png";
// import PrivateEquityFunding from "../../../assets/images/carousel_one/Private Equity Funding .png";
// import ProjectFinance from "../../../assets/images/carousel_one/Project Finance.png";
// import PromoterFunding from "../../../assets/images/carousel_one/Promoter Funding.png";
// import SMELoans from "../../../assets/images/carousel_one/SME Loans.png";
// import VentureCapital from "../../../assets/images/carousel_one/Venture Capital.png";
// import WorkingCapital from "../../../assets/images/carousel_one/Working Capital.png";
// import "../MainBody.css";
// import "./CarouselOne.css";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

// const options = {
//   loop: true,
//   margin: 10,
//   nav: true,
//   responsive: {
//     0: {
//       items: 1.5,
//     },
//     600: {
//       items: 2,
//     },

//     1000: {
//       items: 2,
//     },
//     1080: {
//       items: 6.5,
//     },
//   },
// };

// export default function CarouselOne() {
//   return (
//     <>
//       <Box
//         sx={{
//           width: "100%",
//           height: "100%",
//           backgroundColor: "#EEF5F9",
//           overflow: "hidden",
//           fontFamily: "Daikon-regular",
//           textAlign: "center",
//         }}
//       >
      
//         <OwlCarousel
//           className="owl-theme"
//             loop="true"
//             autoplay="true"
//             // margin={-160}
//           autoplayTimeout="2000"
//           items={7}
//           nav
//           style={{ textAlign: "center" }}
//           {...options}
//           sx={{width:'200px'}}
//         >
//           {/* <div className="item"> */}
//           <Card
          
//             className="item"
//             sx={{
             
//               maxWidth: 210,
//               borderRadius: "10px",
//               boxShadow: 5,
//               marginTop: "40px",
//               marginLeft: "12px",
//               marginBottom: "12px",
//             }}
//           >
//             <CardActionArea>
//               <CardContent sx={{ padding: "0px" }}>
//                 <h4>
//                   <img
//                     src={AcquisitionFunding}
//                     alt="agri"
//                     style={{ width: "12vh", margin: "auto" }}
//                   />
//                   <Typography
//                     sx={{
//                       marginTop: "14px",
//                       "&:hover": { color: "green", cursor: "pointer" },
//                     }}
//                   >
//                     <strong>Acquisition Funding</strong>
//                   </Typography>
//                 </h4>
//               </CardContent>
//             </CardActionArea>
//           </Card>
//           <Card
//             className="item"
//             sx={{
//               maxWidth: 210,
//               borderRadius: "10px",
//               boxShadow: 5,
//               marginTop: "40px",
//               marginLeft: "12px",

//               marginBottom: "12px",
//             }}
//           >
//             <CardActionArea>
//               <CardContent sx={{ padding: "0px" }}>
//                 <h4>
//                   <img
//                     src={AngelInvestment}
//                     alt="agri"
//                     style={{ width: "12vh", margin: "auto" }}
//                   />
//                   <Typography
//                     sx={{
//                       marginTop: "14px",
//                       "&:hover": { color: "green", cursor: "pointer" },
//                     }}
//                   >
//                     <strong>Angel Investment</strong>
//                   </Typography>
//                 </h4>
//               </CardContent>
//             </CardActionArea>
//           </Card>
//           <Card
//             className="item"
//             sx={{
//               maxWidth: 210,
//               borderRadius: "10px",
//               boxShadow: 5,
//               marginTop: "40px",
//               marginLeft: "12px",

//               marginBottom: "12px",
//             }}
//           >
//             <CardActionArea>
//               <CardContent sx={{ padding: "0px" }}>
//                 <h4>
//                   <img
//                     src={CorporateFinance}
//                     alt="agri"
//                     style={{ width: "12vh", margin: "auto" }}
//                   />
//                   <Typography
//                     sx={{
//                       marginTop: "14px",
//                       "&:hover": { color: "green", cursor: "pointer" },
//                     }}
//                   >
//                     <strong>Corporate Finance</strong>
//                   </Typography>
//                 </h4>
//               </CardContent>
//             </CardActionArea>
//           </Card>
//           <Card
//             className="item"
//             sx={{
//               maxWidth: 210,
//               borderRadius: "10px",
//               boxShadow: 5,
//               marginTop: "40px",
//               marginLeft: "12px",

//               marginBottom: "12px",
//             }}
//           >
//             <CardActionArea>
//               <CardContent sx={{ padding: "0px" }}>
//                 <h4>
//                   <img
//                     src={PrivateEquityFunding}
//                     alt="agri"
//                     style={{ width: "12vh", margin: "auto" }}
//                   />
//                   <Typography
//                     sx={{
//                       marginTop: "14px",
//                       "&:hover": { color: "green", cursor: "pointer" },
//                     }}
//                   >
//                     <strong> Private Equity Funding</strong>
//                   </Typography>
//                 </h4>
//               </CardContent>
//             </CardActionArea>
//           </Card>
//           <Card
//             className="item"
//             sx={{
//               maxWidth: 210,
//               borderRadius: "10px",
//               boxShadow: 5,
//               marginTop: "40px",
//               marginLeft: "12px",

//               marginBottom: "12px",
//             }}
//           >
//             <CardActionArea>
//               <CardContent sx={{ padding: "0px" }}>
//                 <h4>
//                   <img
//                     src={ProjectFinance}
//                     alt="agri"
//                     style={{ width: "12vh", margin: "auto" }}
//                   />
//                   <Typography
//                     sx={{
//                       marginTop: "14px",
//                       "&:hover": { color: "green", cursor: "pointer" },
//                     }}
//                   >
//                     <strong> Project Finance</strong>
//                   </Typography>
//                 </h4>
//               </CardContent>
//             </CardActionArea>
//           </Card>
//           <Card
//             className="item"
//             sx={{
//               maxWidth: 210,
//               borderRadius: "10px",
//               boxShadow: 5,
//               marginTop: "40px",
//               marginLeft: "12px",

//               marginBottom: "12px",
//             }}
//           >
//             <CardActionArea>
//               <CardContent sx={{ padding: "0px" }}>
//                 <h4>
//                   <img
//                     src={PromoterFunding}
//                     alt="agri"
//                     style={{ width: "12vh", margin: "auto" }}
//                   />
//                   <Typography
//                     sx={{
//                       marginTop: "14px",
//                       "&:hover": { color: "green", cursor: "pointer" },
//                     }}
//                   >
//                     <strong>Promoter Funding</strong>
//                   </Typography>
//                 </h4>
//               </CardContent>
//             </CardActionArea>
//           </Card>
//           <Card
//             className="item"
//             sx={{
//               maxWidth: 210,
//               borderRadius: "10px",
//               boxShadow: 5,
//               marginTop: "40px",
//               marginLeft: "12px",

//               marginBottom: "12px",
//             }}
//           >
//             <CardActionArea>
//               <CardContent sx={{ padding: "0px" }}>
//                 <h4>
//                   <img
//                     src={SMELoans}
//                     alt="agri"
//                     style={{ width: "12vh", margin: "auto" }}
//                   />
//                   <Typography
//                     sx={{
//                       marginTop: "14px",
//                       "&:hover": { color: "green", cursor: "pointer" },
//                     }}
//                   >
//                     <strong>SME Loans</strong>
//                   </Typography>
//                 </h4>
//               </CardContent>
//             </CardActionArea>
//           </Card>
//           <Card
//             className="item"
//             sx={{
//               maxWidth: 210,
//               borderRadius: "10px",
//               boxShadow: 5,
//               marginTop: "40px",
//               marginLeft: "12px",

//               marginBottom: "12px",
//             }}
//           >
//             <CardActionArea>
//               <CardContent sx={{ padding: "0px" }}>
//                 <h4>
//                   <img
//                     src={VentureCapital}
//                     alt="agri"
//                     style={{ width: "12vh", margin: "auto" }}
//                   />
//                   <Typography
//                     sx={{
//                       marginTop: "14px",
//                       "&:hover": { color: "green", cursor: "pointer" },
//                     }}
//                   >
//                     <strong> Venture Capital</strong>
//                   </Typography>
//                 </h4>
//               </CardContent>
//             </CardActionArea>
//           </Card>
//           <Card
//             className="item"
//             sx={{
//               maxWidth: 210,
//               borderRadius: "10px",
//               boxShadow: 5,
//               marginTop: "40px",
//               marginLeft: "12px",

//               marginBottom: "12px",
//             }}
//           >
//             <CardActionArea>
//               <CardContent sx={{ padding: "0px" }}>
//                 <h4>
//                   <img
//                     src={WorkingCapital}
//                     alt="agri"
//                     style={{ width: "12vh", margin: "auto" }}
//                   />
//                   <Typography
//                     sx={{
//                       marginTop: "14px",
//                       "&:hover": { color: "green", cursor: "pointer" },
//                     }}
//                   >
//                     <strong>Working Capital</strong>
//                   </Typography>
//                 </h4>
//               </CardContent>
//             </CardActionArea>
//           </Card>
//           {/* </div> */}
//         </OwlCarousel>
//         <div style={{ paddingBottom: "20px", marginTop: "18px" }}>
//           {/* <Button variant="contained" color='success' sx={{ backgroundColor: "#35a848" }}><Link style={{ textDecoration: 'none', color: 'white' }} to='/topsectors'>View More</Link></Button> */}
//         </div>
//       </Box>
//     </>
//   );
// }

import React from "react";
import { styled } from "@mui/material/styles";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import './CarouselOne.css'

// Images
import AcquisitionFunding from "../../../assets/images/carousel_one/Acquisition Funding .png";
import AngelInvestment from "../../../assets/images/carousel_one/Angel Investment.png";
import CorporateFinance from "../../../assets/images/carousel_one/Corporate Finance.png";
import PrivateEquityFunding from "../../../assets/images/carousel_one/Private Equity Funding .png";
import ProjectFinance from "../../../assets/images/carousel_one/Project Finance.png";
import PromoterFunding from "../../../assets/images/carousel_one/Promoter Funding.png";
import SMELoans from "../../../assets/images/carousel_one/SME Loans.png";
import VentureCapital from "../../../assets/images/carousel_one/Venture Capital.png";
import WorkingCapital from "../../../assets/images/carousel_one/Working Capital.png";

// Carousel options
const options = {
  loop: true,
  margin: 10,
  nav: true,
  responsive: {
    0: {
      items: 1.5,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 2,
    },
    1080: {
      items: 6.5,
    },
  },
};

// Data array
const cardData = [
  { img: AcquisitionFunding, title: "Acquisition Funding" },
  { img: AngelInvestment, title: "Angel Investment" },
  { img: CorporateFinance, title: "Corporate Finance" },
  { img: PrivateEquityFunding, title: "Private Equity Funding" },
  { img: ProjectFinance, title: "Project Finance" },
  { img: PromoterFunding, title: "Promoter Funding" },
  { img: SMELoans, title: "SME Loans" },
  { img: VentureCapital, title: "Venture Capital" },
  { img: WorkingCapital, title: "Working Capital" },
];

const CarouselOne = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#EEF5F9",
        overflow: "hidden",
        fontFamily: "Daikon-regular",
        textAlign: "center",
      }}
    >
      <OwlCarousel
        className="owl-theme"
        autoplay={true}
        autoplayTimeout={2000}
        nav
        {...options}
      >
        {cardData.map((card, index) => (
          <Card
            key={index}
            className="item"
            sx={{
              maxWidth: 210,
              borderRadius: "10px",
              boxShadow: 5,
              marginTop: "40px",
              marginLeft: "12px",
              marginBottom: "12px",
            }}
          >
            <CardActionArea>
              <CardContent sx={{ padding: "0px" }}>
                <h4>
                  <img
                    src={card.img}
                    alt={card.title}
                    style={{ width: "12vh", margin: "auto" }}
                  />
                  <Typography
                    sx={{
                      marginTop: "14px",
                      "&:hover": { color: "green", cursor: "pointer" },
                    }}
                  >
                    <strong>{card.title}</strong>
                  </Typography>
                </h4>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default CarouselOne;
