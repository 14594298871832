import "./FundRaise.css";
import raisefunding from "../RaiseFunding/Raisefunding.png";
import build from "../RaiseFunding/build.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "@mui/material/Button";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/navbar/index.jsx";
import beyondmoney from "../../assets/images/beyondmoney.jpg";
import fundraise from "../../assets/images/fundraise.jpg";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";

export default function FundRaise() {
  return (
    <>
      <Navbar />
      <Container>
      <div className="fundraisetop">
        <h1>Raising Capital for Your Growth</h1>
        <p>
          "At AQT, we understand that every business has unique needs when it
          comes to raising capital. Whether you're a startup in need of seed
          funding or an established company looking to expand, our fundraising
          services are designed to align with your goals."
        </p>
        {/* <img src={raisefunding} alt="raisefundingimage" /> */}
      </div>
      </Container>
    
      <Container>
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>

        <div className="raisingsme">
            <Grid item xs={4} sm={8} md={6}>
              <div className="raisingsme1">
                <h3 className="fundraiseheading3">
                  Our Fundraising Process Is Tailored To Meet Your Specific
                  Objectives, and We Offer an Array Of Services That Go Beyond
                  Just Raising Money
                </h3>
                <div className="fundraisedisplay">
                  <CheckCircleIcon
                    sx={{ color: "green", padding: "1rem 0rem 0rem 0rem" }}
                  />
                  <p className="mainheadfundraise">
                    <strong>Virtual CFO Services:</strong>
                  </p>
                  <p className="threefundraise" style={{ marginLeft: "1rem" }}>
                    Our team of experienced financial officers provides Virtual
                    CFO services, offering full financial management support.
                    This includes budgeting, forecasting, and financial
                    strategy. Our virtual CFOs ensure that your financials are
                    optimized and fully compliant with regulatory requirements.
                  </p>
                </div>
                <div className="fundraisedisplay">
                  <CheckCircleIcon
                    sx={{ color: "green", padding: "1rem 0rem 0rem 0rem" }}
                  />
                  <p className="mainheadfundraise">
                    <strong>Equity and Debt Structuring:</strong>
                  </p>
                  <p>
                    Whether your business needs equity financing or debt
                    solutions, our advisors will help you determine the best
                    structure. We guide you through the nuances of different
                    fundraising options, ensuring that you have access to the
                    capital you need without compromising your long-term
                    financial health.
                  </p>
                </div>
                <div className="fundraisedisplay">
                  <CheckCircleIcon
                    sx={{ color: "green", padding: "1rem 0rem 0rem 0rem" }}
                  />
                  <p className="mainheadfundraise">
                    <strong>Customized Capital Strategies: </strong>
                  </p>
                  <p>
                    We understand that one size doesn’t fit all. That’s why we
                    craft customized capital strategies that fit your industry,
                    business model, and growth phase. Our approach ensures that
                    you not only receive capital but also the guidance and
                    resources to use it effectively.
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item xs={4} sm={8} md={6}>
              <img
                style={{ width: "100%" }}
                src={fundraise}
                alt="raisingsmeImage"
              />
            </Grid>
         
        </div>
        </Grid>
      </Container>
      <Container>
        <div className="raisingsme">
          <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={8} md={6}>
              <div className="raisingsme1">
                <h1 className="fundraiseheading3">
                  Additional Fundraising Support
                </h1>
                <div className="fundraisedisplay">
                  <CheckCircleIcon
                    sx={{ color: "green", padding: "1rem 0rem 0rem 0rem" }}
                  />
                  <p className="mainheadfundraise">
                    <strong>Investor Presentation Preparation:</strong>
                  </p>
                  <p className="valuefundpara">
                    At Least Three Year Track Record.The goal is to inspire
                    confidence and attract investment by clearly outlining how
                    the company plans to deliver value and generate returns.
                  </p>
                </div>
                <div className="fundraisedisplay">
                  <CheckCircleIcon
                    sx={{ color: "green", padding: "1rem 0rem 0rem 0rem" }}
                  />
                  <p className="mainheadfundraise">
                    <strong>Valuation Advisory:</strong>
                  </p>
                  <p
                    className="valuefundpara"
                    style={{ paddingLeft: "2.5rem" }}
                  >
                    AQT provides an accurate, in-depth analysis of your
                    business's value, positioning you to negotiate confidently
                    with investors.
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item xs={4} sm={8} md={6}>
              <img
                src={build}
                alt="raisingsmeImage"
                style={{ height: "12rem", paddingTop: "3rem" }}
              />
            </Grid>
          </Grid>
        </div>
      </Container>
      <Footer />
    </>
  );
}
