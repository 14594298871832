import React, { useEffect } from "react";
import Navbar from "../../components/navbar/index";
import Footer from "../../components/Footer/Footer";
import {
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import aboutus2 from "../../assets/images/aboutus2.jpeg";
import mission from "../../assets/images/mission.jpg";
import vision from "../../assets/images/vision1.png";
import ceo from "../../assets/images/ceo.jpg";
import dummy from "../../assets/images/dummyuser.png";
import AboutImage from "../../assets/images/carousel_one/aboutsUs.png";
import startup1 from "../../assets/images/carousel_one/about.jpg";



export default function AboutUs() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Navbar />

      {/* Hero Section */}
      {/* <Box
        sx={{
          width: "100%",
          height: "400px",
          overflow: "hidden",
          marginBottom: "2rem",
        }}
      >
        <img
          src={aboutus2}
          alt="About Us"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box> */}

<Box sx={{ position: "relative", mb: 4 }}>
        <img
          src={startup1}
          alt="aboutus"
          style={{
            width: "100%",
            height: "24rem",
            objectFit: "cover",
            display: "block",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#fff",
            textAlign: "center",
            // background: "rgba(0, 0, 0, 0.5)",
            padding: "1rem 2rem",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h3" fontWeight="bold">
           About Us
          </Typography>
        </Box>
      </Box>


      {/* Main Container */}
      <Container sx={{ paddingY: "3rem" }}>
        {/* <Grid>
          
          <Box>
            <Typography
              variant="h4"
              align="center"
              fontWeight="bold"
              sx={{
                marginBottom: "2rem",
                fontFamily: "Daikon-regular",
                letterSpacing: "0.5px",
              }}
            >
              AQT Direct Limited: Leading Private Equity Investment Consultancy
            </Typography>

            <Typography
              variant="body1"
              align="center"
              sx={{
                maxWidth: "800px",
                margin: "0 auto",
                lineHeight: "1.8",
                fontSize: "1.1rem",
                fontFamily: "Daikon-regular",
                textAlign: "start",
              }}
            >
              At AQT, we're not just a Private Equity Consultancy firm; we're
              architects of opportunity and architects of change. With a
              deep-rooted commitment to excellence and innovation, we specialize
              in reshaping the private equity landscape through strategic
              insights, dynamic partnerships, and sustainable growth
              initiatives.
            </Typography>

            </Box>
         
       

        <Typography
          variant="body1"
          align="center"
          sx={{
            maxWidth: "800px",
            margin: "1rem auto",
            lineHeight: "1.8",
            fontSize: "1.1rem",
            fontFamily: "Daikon-regular",
            textAlign: "start",
          }}
        >
          Driven by a passion for progress and a vision for a brighter tomorrow,
          we're dedicated to fueling the evolution of India's business
          ecosystem. From pioneering investment models to fostering impactful
          change, we're shaping the future of private equity in India and
          beyond. With a focus on integrity, collaboration, and unwavering
          dedication, we're not just shaping businesses; we're shaping legacies.
        </Typography>
        </Grid> */}

        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{ maxWidth: "1200px", margin: "0 auto" }}
        >
           <Typography
                variant="h4"
                align="center"
                fontWeight="bold"
                sx={{
                  marginBottom: "1.8rem",
                  letterSpacing: "0.5px",
                }}
              >
                AQT Direct Limited: Leading Private Equity Investment
                Consultancy
              </Typography>
          {/* Left Image */}
             {/* Right Content */}
             <Grid item xs={12} sm={6} md={7}>
            <Box>
              {/* <Typography
                variant="h4"
                align="center"
                fontWeight="bold"
                sx={{
                  marginBottom: "2rem",
                  letterSpacing: "0.5px",
                }}
              >
                AQT Direct Limited: Leading Private Equity Investment
                Consultancy
              </Typography> */}

              <Typography
                variant="body1"
                align="center"
                sx={{
                  maxWidth: "800px",
                  margin: "0 auto",
                  lineHeight: "1.6",
                  fontSize: "1.1rem",
                  textAlign: "start",
                }}
              >
                At AQT, we're not just a Private Equity Consultancy firm; we're
                architects of opportunity and architects of change. With a
                deep-rooted commitment to excellence and innovation, we
                specialize in reshaping the private equity landscape through
                strategic insights, dynamic partnerships, and sustainable growth
                initiatives.
              </Typography>

              <Typography
                variant="body1"
                align="center"
                sx={{
                  maxWidth: "800px",
                  margin: "1rem auto",
                  lineHeight: "1.6",
                  fontSize: "1.1rem",
                  textAlign: "start",
                }}
              >
                Driven by a passion for progress and a vision for a brighter
                tomorrow, we're dedicated to fueling the evolution of India's
                business ecosystem. From pioneering investment models to
                fostering impactful change, we're shaping the future of private
                equity in India and beyond. With a focus on integrity,
                collaboration, and unwavering dedication, we're not just shaping
                businesses; we're shaping legacies.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <Box
              component="img"
              src={AboutImage}
              alt="AQT Direct Limited"
              sx={{
                width: "100%",
                height: "auto",
                // borderRadius: "8px",
                // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              }}
            />
          </Grid>

       
        </Grid>

        <Typography
          variant="h5"
          fontWeight="bold"
          align="center"
          sx={{
            marginTop: "3rem",
            marginBottom: "2rem",
           
          }}
        >
          Our Mission & Vision
        </Typography>

        {/* Mission and Vision Cards */}
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Card sx={{ boxShadow: 5, borderRadius: 8 }}>
              <CardMedia
                component="img"
                height="250"
                image={mission}
                alt="Mission"
              />
              <CardContent>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Our Mission
                </Typography>
                <Typography variant="" sx={{ lineHeight: "1.6", fontSize:'1.1rem', }}>
                  AQT Direct Limited is committed to enhancing financial
                  literacy across India. We strive to educate individuals about
                  smart investments, nurturing them into angel investors for the
                  future of a prosperous economy.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Card sx={{ boxShadow: 5, borderRadius: 8 }}>
              <CardMedia
                component="img"
                height="250"
                image={vision}
                alt="Vision"
              />
              <CardContent>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Our Vision
                </Typography>
                <Typography variant="body2" sx={{ lineHeight: "1.6", fontSize:'1.1rem', }}>
                  We envision a world where trust and capital flow freely,
                  empowering communities and businesses to grow together. AQT
                  aims to elevate India to a leading global economy through
                  innovative investments.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* CEO and CBO Messages */}
        {/* <Typography
          variant="h5"
          fontWeight="bold"
          align="center"
          sx={{
            marginTop: "4rem",
            marginBottom: "2rem",
            fontFamily: "Daikon-regular",
          }}
        >
          Leadership Messages
        </Typography> */}

        {/* <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Card sx={{ boxShadow: 3 }}>
              <CardMedia
                component="img"
                height="400"
                image={ceo}
                alt="CEO"
                sx={{ objectFit: "cover" }} // Options: "cover", "contain", "fill"

              />
              <CardContent>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Message from the CEO
                </Typography>
                <Typography variant="body2" sx={{ lineHeight: "1.6" }}>
                  By nurturing small enterprises, we generate employment, spark
                  creativity, and drive economic growth in local communities.
                  This is a vital step in realizing India's ambition of becoming
                  a global economic leader.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

      
        </Grid> */}

        <Box display='flex' justifyContent='center'>
        <Grid justifyContent="center" sx={{marginTop:'3rem'}}> 
          <Grid item xs={12} sm={8}>
            <Card
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                boxShadow: 5,
                borderRadius: 8,
               maxWidth:1000
              }}
            >
              {/* Image Section */}
              <CardMedia
                component="img"
                image={ceo}
                alt="CEO"
                sx={{
                  width: { sm: "40%" }, // Takes half the width on larger screens
                  height: { xs: 300, sm: "auto" }, // Adjusts height based on screen size
                  maxHeight: 395,
              
                  objectFit: "cover",
                }}
              />


              {/* Content Section */}
              <CardContent sx={{ flex: 1, padding: 3 }}>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Message from the CEO
                </Typography>
                <Typography variant="body2" sx={{ lineHeight: "1.6" , fontSize:'1.1rem',}}>
                At AQT Direct, we believe small enterprises are the backbone of a thriving economy. By nurturing these businesses, we create jobs, inspire innovation, and drive sustainable growth in local communities. Our mission goes beyond funding—we empower entrepreneurs to unlock their potential and contribute to India's vision of becoming a global economic leader. Together, we are shaping a brighter, more prosperous future.

                </Typography>
                <Typography sx={{marginTop:'2px'}}>
                 <strong>-Mr. Nagesh Jakhar</strong>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        </Box>
      </Container>

      <Footer />
    </>
  );
}
