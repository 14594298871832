

import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Card, Typography } from "@mui/material";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Agriculture from "../../../assets/images/Agriculture.png";
import IT from "../../../assets/images/IT.jpg";
import Finance from "../../../assets/images/Finance.jpg";
import Health from "../../../assets/images/Health.jpg";
import Sales from "../../../assets/images/Sales.jpg";
import Media from "../../../assets/images/Media.jpg";
import Marketing from "../../../assets/images/Marketing.jpg";
import Stocks from "../../../assets/images/Stocks.jpg";
import Data from "../../../assets/images/Data.jpg";
import Business from "../../../assets/images/Buisness.jpg";
import "../MainBody.css";

const options = {
  loop: true,
  margin: 10,
  nav: true,
  responsive: {
    0: { items: 1.5 },
    600: { items: 2 },
    1000: { items: 2 },
    1080: { items: 6.5 },
  },
};

const cardData = [
  { title: "Agriculture", image: Agriculture },
  { title: "IT", image: IT },
  { title: "Finance", image: Finance },
  { title: "Health", image: Health },
  { title: "Sales", image: Sales },
  { title: "Media", image: Media },
  { title: "Marketing", image: Marketing },
  { title: "Stocks", image: Stocks },
  { title: "Data", image: Data },
  { title: "Business", image: Business },
];

export default function BodyInside() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: "#EEF5F9",
        overflow: "hidden",
        fontFamily: "Daikon-regular",
        textAlign: "center",
      }}
    >
      <h1>Top Sectors In Private Equity Market</h1>
      <Box display={"flex"} justifyContent={"center"}>
        <Typography className="owlBox">
          At AQT Direct, we simplify private equity, offering expert guidance to
          navigate limited partnerships in promising businesses. Our services
          include research and audit, equity deal compliances, value creation,
          exit strategies, and much more. Explore how AQT Direct can help you
          achieve your investment goals today.
        </Typography>
      </Box>

      <OwlCarousel
        className="owl-theme"
        loop
        autoplay
        margin={-160}
        autoplayTimeout={3000}
        nav
        {...options}
        style={{ textAlign: "center" }}
      >
        {cardData.map((card, index) => (
          <Card
            key={index}
            className="item"
            sx={{
              boxShadow: 5,
              borderRadius: "12px",
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <h4>
              <img
                className="carouselDiv"
                src={card.image}
                alt={card.title.toLowerCase()}
                style={{ width: "20vh", margin: "auto" }}
              />
              <Typography
                sx={{
                  marginTop: "24px",
                  "&:hover": { color: "green", cursor: "pointer" },
                }}
              >
                {card.title}
              </Typography>
            </h4>
          </Card>
        ))}
      </OwlCarousel>

      <div style={{ paddingBottom: "20px", marginTop: "18px" }}>
        {/* Optional "View More" button */}
      </div>
    </Box>
  );
}
