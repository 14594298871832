// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Rating from '@mui/material/Rating';
// import Container from '@mui/material/Container';
// import Google from './Google.jpg';
// import Google1 from './Google1.png';
// import google2 from '../../../assets/images/google2.svg';

// export default function Testimonial() {
//   const [value, setValue] = React.useState(5);
//   return (

//     <React.Fragment>
//       <Box style={{ backgroundColor: "#05053a", padding:'1rem 2rem 1rem 2rem' }}>
//         <Box sx={{ fontSize: "2.5rem", textAlign:'center', fontWeight: 700, color: "white", fontFamily:'Daikon-regular' }}>Testimonials</Box>

//         <Box sx={{ marginTop: "2rem" }}>
//           <Box sx={{ display: "flex", flexDirection: "row" }}>
//             <Box sx={{ width: "50rem", height: "17rem", backgroundColor: "white", margin: "1rem", borderRadius: "15px" }}>
//               <CardContent >
//                 <Typography variant="h6" component="div">
//                 Effective PE investment risk mitigation
//                 </Typography>
//                 <Box sx={{
//                   '& > legend': { mt: 2 },
//                 }}>
//                   <Rating name="read-only" value={value} readOnly />
//                 </Box>
//                 <Typography sx={{ mb: 1.5, fontSize: "0.9rem" }} color="text.secondary">
//                   "I am a CFA candidate, and I realised the risks and complications associated with the private equity market in India. I am happy and surprised to see the risk mitigation techniques and smooth processes of PE investments at AQT"
//                 </Typography>
//                 <Box sx={{ display: "flex" }}>
//                   <Typography variant="body2" sx={{ fontSize: "medium", fontWeight: 600, padding: "1.5rem 0.3rem 0rem 0rem" }}>
//                   Kartikeya Aggarwal
//                   </Typography>
//                   {/* <Typography sx={{ color: "darkgray", padding: "1.5em 1em 0em 0em" }}>(Long Term Investor)</Typography> */}
//                 </Box>
//               </CardContent>
//             </Box>
//             <Box sx={{ width: "50rem", height: "17rem", backgroundColor: "white", margin: "1rem", borderRadius: "15px" }}>
//               <CardContent>
//                 <Typography variant="h6" component="div">
//                 Ensured fair valuation, avoiding bubble
//                 </Typography>
//                 <Box sx={{
//                   '& > legend': { mt: 2 },
//                 }}>
//                   <Rating name="read-only" value={value} readOnly />
//                 </Box>
//                 <Typography sx={{ fontSize: "0.9rem" }} color="text.secondary">
//                   "In private equity valuation is a bubble and Anuj Ji's team took good care of fair valuation in their products.. "
//                 </Typography>
//                 <Box sx={{ display: "flex", paddingTop: "1.5em", margin: "3.2rem 0rem 0rem 0rem" }}>
//                   <Typography variant="body2" sx={{ fontSize: "medium", fontWeight: 600 }}>
//                   Raj Thakur
//                   </Typography>
//                   {/* <Typography sx={{ color: "darkgray" }}>(Equity Investor)</Typography> */}
//                 </Box>
//               </CardContent>
//             </Box >
//             <Box sx={{ width: "50rem", height: "17rem", backgroundColor: "white", margin: "1rem", borderRadius: "15px" }}>
//               <CardContent>
//                 <Typography variant="h6" component="div">
//                 Impressed by AQT team's expertise
//                 </Typography>
//                 <Box sx={{
//                   '& > legend': { mt: 2 },
//                 }}>
//                   <Rating name="read-only" value={value} readOnly />
//                 </Box>
//                 <Typography sx={{ mb: 1.5, fontSize: "0.9rem" }} color="text.secondary">
//                   "Interacted with the team at an event in Delhi, these guys have a great understanding of the numbers and the needs of the customer. Looking forward for the meeting, as discussed with Me Nikhil, AQT.  "
//                 </Typography>
//                 <Box sx={{ display: "flex" }}>
//                   <Typography variant="body2" sx={{ fontSize: "medium", fontWeight: 600, padding: "2.8rem 0.3rem 0rem 0rem" }}>
//                   Mayank Kumar
//                   </Typography>
//                   {/* <Typography sx={{ color: "darkgray", padding: "1.5rem 1rem 0rem 0rem" }}>(Ace Investor)</Typography> */}
//                 </Box>
//               </CardContent>
//             </Box>
//           </Box>
//         </Box>

//       </Box>
//     </React.Fragment>
//   );
// }

import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import { Container, Grid } from "@mui/material";

const testimonials = [
  {
    title: "Effective PE investment risk mitigation",
    text: "I am a CFA candidate, and I realised the risks and complications associated with the private equity market in India. I am happy and surprised to see the risk mitigation techniques and smooth processes of PE investments at AQT.",
    name: "Kartikeya Aggarwal",
  },
  {
    title: "Ensured fair valuation, avoiding bubble",
    text: "In private equity, valuation is a bubble, and Anuj Ji's team took good care of fair valuation in their products.",
    name: "Raj Thakur",
  },
  {
    title: "Impressed by AQT team's expertise",
    text: "Interacted with the team at an event in Delhi; these guys have a great understanding of the numbers and the needs of the customer. Looking forward to the meeting, as discussed with Me Nikhil, AQT.",
    name: "Mayank Kumar",
  },
];

export default function Testimonial() {
  const [value] = React.useState(5);
  return (
    <React.Fragment>
      <Box sx={{ backgroundColor: "#05053a", padding: "2rem 0" }}>
        <Box
          sx={{
            fontSize: "2rem",
            textAlign: "center",
            fontWeight: 700,
            color: "white",
            fontFamily: "Daikon-regular",
            marginBottom: "2rem",
          }}
        >
          Testimonials
        </Box>

        <Container>
          {/* <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}> */}
          <Grid container spacing={0} columns={{ xs: 4, sm: 8, md: 12 }}>
            {testimonials.map((testimonial, index) => (
              // <Grid item="true" xs={4}
              //   key={index}
              //   sx={{
              //     marginBottom:'42px',
              //     marginRight:'6px',
              //     maxWidth:80,
              //     // height: "18rem",
              //     backgroundColor: "white",
              //     borderRadius: "15px",
              //     boxShadow: 3,
              //     transition: "transform 0.2s",
              //     "&:hover": {
              //       transform: "scale(1.02)",
              //       boxShadow: 6,
              //     },
              //   }}
              // >
              <Card
                key={index}
                sx={{
                  marginBottom: "22px",
                  marginRight: "12px",
                  maxWidth: 350,
                  // height: "18rem",
                  backgroundColor: "white",
                  borderRadius: "15px",
                  boxShadow: 3,
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "scale(1.02)",
                    boxShadow: 6,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontWeight: "bold" }}
                  >
                    {testimonial.title}
                  </Typography>
                  <Rating
                    name="read-only"
                    value={value}
                    readOnly
                    // sx={{ marginBottom: "0.5rem" }}
                  />
                  <Typography
                    sx={{ fontSize: "0.85rem", color: "text.secondary" }}
                  >
                    "{testimonial.text}"
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "medium",
                      fontWeight: 600,
                      // marginTop: "1rem",
                    }}
                  >
                    {testimonial.name}
                  </Typography>
                </CardContent>
              </Card>
              // </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
}
