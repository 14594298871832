import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/navbar/index.jsx";
import Button from "@mui/material/Button";
import "./MainBody.css";
import speaker from "./MainPageContent/speaker.png";
import Typography from "@mui/material/Typography";
import BodyInside from "./MainPageContent/BodyInside";
import BodyTwo from "./MainPageContent/BodyTwo";
import EndPage from "./MainPageContent/EndPage";
import TablePage from "./MainPageContent/Table";
import Testimonial from "./MainPageContent/Testimonial";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect } from "react";
import Container from "@mui/material/Container";
import ShareCard from "../../components/cards/ShareCard.jsx";
import Grid from "@mui/material/Unstable_Grid2";
import main from "../../assets/images/main-trade.png";
import bulb from "../../assets/images/bull-bulb-line.png";
import { useState } from "react";
import { api, imageApi } from "../../components/Api/Api.jsx";
import { redirect } from "../../components/Api/Api.jsx";
import { dataShow } from "./DataMain.js";
import globe from "../../assets/images/globe.jpg";
import aqt from "../../assets/images/aqt.png";
import { textAlign } from "@mui/system";
import CompanyCard from "../../components/cards/CompanyCard.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import EquityDesign from "./EquityDesign.jsx";
import CarouselOne from "./MainPageContent/CarouselOne.jsx";
import mainBanner from "../../assets/images/carousel_one/1.webp";

const cardPerPage = 6;
export default function MainBody({ idpoly, headings, images, url }) {
  const [data, setData] = useState(null);
  const [dataOnPage, setDataOnPage] = useState(null);
  const [search, setSearch] = useState("");
  const [pages, setPages] = useState(1);
  const lastIndex = cardPerPage * pages;
  const firstIndex = lastIndex - cardPerPage;

  const FetchData = async () => {
    try {
      await fetch(api)
        .then((res) => res.json())
        .then((res) => setData(res.detail.rows.slice(-6)));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchData();
  }, []);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const filter =
    data !== null
      ? data.filter((f) => f.name.toLowerCase().includes(search.toLowerCase()))
      : [];

  console.log("filter in main", filter);

  // const redir = function redirectToPloy(id){
  //     navigate('/polymatech',{state:id})
  // }

  return (
    <>
      <Navbar />

      {/* Main Banner */}

      {/* <div className="main-banner">
        <h1>Managing & Maximizing Private Equity </h1>
        <p>
          Welcome to AQT Direct Limited, your gateway to managing and maximizing
          private equity investments.
          <br />
          We specialize in building fortunes and creating legacies for our
          clients, while safeguarding them from valuation bubbles.{" "}
        </p>
      </div> */}
      {/* new */}
      {/* <div className="main-banner">
        <img src={mainBanner} alt="Banner" />
        
      </div> */}
      {/* <div className="main-banner">
  <img src={mainBanner} alt="Banner" />
  <div className="banner-content">
    <h1>SCALE YOUR BUSINESS 
    </h1>
    <p>
    Achieve exponential growth with AQT's tailored financial  solutions designed to meet your business needs.
 
    </p>
  </div>
</div> */}

      <div className="main-banner">
        <img src={mainBanner} alt="Banner" />
        <div className="banner-content">
          <h1>SCALE YOUR BUSINESS</h1>
          <p>
            Achieve exponential growth with AQT's tailored{" "}
            <span className="break-after">financial</span> solutions designed to
            meet your business needs.
          </p>
        </div>
      </div>

      {/* Main Banner End */}

      <CarouselOne />

      <Container>
        <div className="searchbar">
          <input
            className="inputmain"
            type="text"
            placeholder="Search..."
            value={search}
            onChange={handleChange}
          />
          <SearchIcon
            sx={{ margin: "0.7rem 0rem 0rem -2rem" }}
            className="search-icon"
          />
        </div>

        <div>
          <div>
            <Grid container spacing={3}>
              {filter !== null ? (
                filter.map((company) => (
                  <Grid item xs={12} sm={6} md={4} key={company.id}>
                    <CompanyCard
                      imageUrl={`${imageApi}${company.cmpImg}`}
                      onClick={() => {
                        headings(company.name);
                        idpoly(company.id);
                        images(`${imageApi}${company.cmpImg}`);
                      }}
                      name={company.name}
                      id={company.id}
                      ipoYear={company.ipoYear}
                      url={company.websiteUrl}
                      about={company.about}
                      address={company.address}
                      directorName={company.directorName}
                      registrationDate={company.registrationDate}
                      mail={company.mail}
                      city={company.city}
                      totalShares={company.totalShares}
                      perShareValue={company.perShareValue}
                      overview={company.overview}
                      productAndServices={company.productAndServices}
                    />
                  </Grid>
                ))
              ) : (
                <p>No results found.</p>
              )}
            </Grid>
          </div>
        </div>

        {filter.length > 0 && (
          <div
            style={{
              textAlign: "end",
              paddingTop: "20px",
              paddingBottom: "25px",
            }}
          >
            <Button variant="contained" color="success" className="view-more">
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/companylist"
              >
                View More &nbsp;
                <FontAwesomeIcon icon={faEye} />
              </Link>
            </Button>
          </div>
        )}
      </Container>

      <BodyInside />

      <EquityDesign />
      <Testimonial />
      <EndPage />
      <Footer />
    </>
  );
}
