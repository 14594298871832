import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Box from '@mui/material/Box';
import sharenews1 from "../../../assets/images/sharenews1.png";
import sharenews2 from "../../../assets/images/sharenews2.png";
import sharenews3 from "../../../assets/images/sharenews3.png";

export default function EndPage() {
  return (
    <div>
      <Box sx={{ textAlign: 'center', fontFamily: 'Daikon-regular', padding: '12px' }}>
        <h1>Equity Shares Blogs</h1>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' }, // Column on small screens, row on larger screens
          alignItems: 'center',
          justifyContent: 'center',
          margin: '1rem',
          gap: '1rem', // Add spacing between cards
        }}
      >
        <Card sx={{ maxWidth: 380, width: '100%' }}>
          <CardActionArea>
            <CardMedia
              sx={{ minHeight: 300, cursor: 'default' }}
              component="img"
              image={sharenews2}
              alt="Blog 1"
            />
            <CardContent>
              <Box sx={{ textDecoration: 'none' }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{
                    fontSize: 'larger',
                    fontWeight: 600,
                    color: 'black',
                    '&:hover': { color: 'green' },
                    fontFamily: 'Daikon-regular',
                  }}
                >
                  How to conduct due diligence on pre-IPO companies before investing.
                </Typography>
              </Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ cursor: 'text', fontFamily: 'Daikon-regular' }}
              >
                Uncovering the hidden gems: A guide to pre-IPO due diligence. Due diligence is an
                essential step when considering investing in pre-IPO companies. It's the process of
                gathering and evaluating information about a company to determine whether it's a
                viable investment opportunity.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 380, width: '100%' }}>
          <CardActionArea>
            <CardMedia
              sx={{ minHeight: 300, cursor: 'default' }}
              component="img"
              image={sharenews1}
              alt="Blog 2"
            />
            <CardContent>
              <Box sx={{ textDecoration: 'none' }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{
                    fontSize: 'larger',
                    fontWeight: 600,
                    color: 'black',
                    '&:hover': { color: 'green' },
                    fontFamily: 'Daikon-regular',
                  }}
                >
                  The impact of the current economic climate on pre-IPO investing.
                </Typography>
              </Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ cursor: 'text', fontFamily: 'Daikon-regular' }}
              >
                The current economic climate can have a significant impact on pre-IPO investing.
                Economic conditions can affect a company's financial performance, ability to go
                public, and demand for its stock once it does.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 380, width: '100%' }}>
          <CardActionArea>
            <CardMedia
              sx={{ minHeight: 300, cursor: 'default' }}
              component="img"
              image={sharenews3}
              alt="Blog 3"
            />
            <CardContent>
              <Box sx={{ textDecoration: 'none' }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{
                    fontSize: 'larger',
                    fontWeight: 600,
                    color: 'black',
                    '&:hover': { color: 'green' },
                    fontFamily: 'Daikon-regular',
                  }}
                >
                  Differences between pre-IPO and post-IPO investing
                </Typography>
              </Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ cursor: 'text', fontFamily: 'Daikon-regular' }}
              >
                Beyond the public offering: Understanding the long-term implications of pre-IPO and
                post-IPO investing. Pre-IPO and post-IPO investing are both ways to invest in a
                company, but they differ in several key ways.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
    </div>
  );
}
