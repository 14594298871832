import React from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/navbar/index.jsx";
import loan from "../../assets/images/loan.jpg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import "./FundRaise.css";
import { Container, Grid } from "@mui/material";

export default function SMELoans() {
  return (
    <>
      <Navbar />

      <div className="mediacover1">
        <Grid
          style={{ marginTop: "1px" }}
          container
          spacing={2}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={6}>
            <div className="mediacover11">
              <h1>Tailored Financial Solutions for SMEs</h1>
              <p>
                Small and medium-sized enterprises are critical drivers of the
                economy, and we understand the importance of access to flexible,
                reliable financing. At AQT, we offer a range of loan options
                specifically designed to meet the needs of SMEs, whether it’s
                for expansion, working capital, or new projects.{" "}
              </p>
            </div>
          </Grid>
          <Grid item xs={4} sm={8} md={6}>
            <img src={loan} style={{ width: "100%" }} alt="articalimage" />
          </Grid>
        </Grid>
      </div>
      <Container>
      <h1 className="headingsmeloans">Our SME loan services include</h1>
      <div className="bodysmeloans">
        <Grid
          style={{ marginTop: "12px" }}
          container
          spacing={2}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={4}>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Competitive Rates
                  </Typography>
                  <Typography variant="body2">
                    We offer some of the most competitive interest rates in the
                    market, helping you secure the financing you need without
                    putting undue pressure on your cash flow.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={4} sm={8} md={4}>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Flexible Repayment Terms
                  </Typography>
                  <Typography variant="body2">
                    We understand the challenges that SMEs face, so we offer
                    flexible repayment terms that align with your cash flow
                    cycles.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={4} sm={8} md={4}>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Quick Approval Process
                  </Typography>
                  <Typography variant="body2">
                    Our streamlined loan approval process ensures that you get
                    access to funds when you need them most.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </div>
      </Container>
      <Footer />
    </>
  );
}
