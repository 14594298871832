import * as React from "react";
import Navbar from "../../../components/navbar/index";
import Footer from "../../../components/Footer/Footer";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import ShareCard from "../../../components/cards/ShareCard";
import { api, imageApi } from "../../../components/Api/Api";
import { dataShow } from "../DataMain";
import CompanyCard from "../../../components/cards/CompanyCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import SearchIcon from "@mui/icons-material/Search";
import { Container } from "@mui/material";


export default function CardViewMore({ idpoly, headings }) {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  const FetchData = async () => {
    await fetch(api)
      .then((res) => res.json())
      .then((res) => setData(res.detail.rows));
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const filter =
    data !== null
      ? data.filter((f) => f.name.toLowerCase().includes(search.toLowerCase()))
      : [];

  useEffect(() => {
    FetchData();
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Navbar />
      {/* <Box
        sx={{
          textAlign: "center",
          paddingBottom: "1rem",
          paddingTop: "2.5rem",
        }}
      >
        <TextField
          value={search}
          onChange={handleChange}
          id="outlined-basic"
          label="Search"
          variant="outlined"
          sx={{ width: 400 }}
        />
      </Box> */}

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          marginLeft: "6%",
          marginRight: "6%",
          padding: "2rem",
        }}
      >
        {/* <Grid
          container
          spacing={0}
          rowSpacing={1.2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {filter !== null
            ? filter.map((company) => (
                <Grid item xs={4} key={company.id}>
                  
                  <CompanyCard
                    imageUrl={`${imageApi}${company.cmpImg}`}
                    onClick={() => {
                      headings(company.name);
                      idpoly(company.id);
                    }}
                    
                    name={company.name}
                    id={company.id}
                    ipoYear={company.ipoYear}
                    url={company.websiteUrl}
                    about={company.about}
                    address={company.address}
                    directorName={company.directorName}
                    registrationDate={company.registrationDate}
                    mail={company.mail}
                    city={company.city}
                    totalShares={company.totalShares}
                    perShareValue={company.perShareValue}
                    overview={company.overview}
                  />
                </Grid>
              ))
            : ""}

       
        </Grid> */}


        {/* New Mapped container */}

        <Container>
        {/* {filter.length > 0 && ( */}
        <div className="searchbar">
          {/* <img className="image" src={search} alt="searching"></img> */}
          <input
            className="inputmain"
            type="text"
            placeholder="Search..."
            value={search}
            onChange={handleChange}
          />
          <SearchIcon
            sx={{ margin: "0.7rem 0rem 0rem -2rem" }}
            className="search-icon"
          />

          {/* <button className='buttonNav' type='button'>Search</button> */}
        </div>
        {/* )} */}
        <div >
          <div >
            <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
              {filter !== null
                ? filter.map((company) => (
                    <Grid item="true" xs={4} key={company.id}>
                      {/* <ShareCard
                        imageUrl={`${imageApi}${company.cmpImg}`}
                        onClick={() => {
                          headings(company.name);
                          idpoly(company.id);
                          images(`${imageApi}${company.cmpImg}`);
                        }}
                        
                        name={company.name}
                        ipoYear={company.ipoYear}
                        url={company.websiteUrl}
                      /> */}
                      <CompanyCard
                        imageUrl={`${imageApi}${company.cmpImg}`}
                        onClick={() => {
                          headings(company.name);
                          idpoly(company.id);
                          // images(`${imageApi}${company.cmpImg}`);
                        }}
                        name={company.name}
                        id={company.id}
                        ipoYear={company.ipoYear}
                        url={company.websiteUrl}
                        about={company.about}
                        address={company.address}
                        directorName={company.directorName}
                        registrationDate={company.registrationDate}
                        mail={company.mail}
                        city={company.city}
                        totalShares={company.totalShares}
                        perShareValue={company.perShareValue}
                        overview={company.overview}
                        productAndServices={company.productAndServices}
                      />
                    </Grid>
                  ))
                : ""}
            </Grid>
          </div>
        </div>

        {/* {filter.length > 0 && (
          <div
            style={{
              textAlign: "end",
              paddingTop: "20px",
              paddingBottom: "25px",
            }}
          >
            <Button variant="contained" color="success" className="view-more">
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/companylist"
              >
                View More &nbsp;
                <FontAwesomeIcon icon={faEye} />
              </Link>
            </Button>
          </div>
        )} */}
      </Container>
       
      </Box>
      <Footer />
    </>
  );
}
