import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import { Link } from "react-router-dom";
import { Link as MuiLink, Box, Stack, Container, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";

export default function CompanyCard({
  imageUrl,
  name,
  id,
  onClick,
  url,
  about,
  directorName,
  mail,
  city,
  totalShares,
  perShareValue,
  overview,
  ipoYear,
  productAndServices,
}) {
  return (
    <Card sx={{ maxWidth: 350, borderRadius: "10px", boxShadow: 3 }}>
      <CardActionArea>
        <CardContent sx={{ padding: "16px" }}>
          {/* Container for logo and name */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
              {url ? (
                <MuiLink
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={onClick}
                  sx={{
                    color: "black",
                    textDecoration: "none", 
                    "&:hover": {
                      textDecoration: "underline",
                      color: "#1976d2",
                    },
                  }}
                >
                  {name.toUpperCase()}
                </MuiLink>
              ) : (
                <Link
                  to="#"
                  onClick={onClick}
                  style={{
                    color: "black",
                    textDecoration: "none",
                    "&:hover": { textDecoration: "underline" },
                  }}
                >
                  {name.toUpperCase()}
                </Link>
              )}
            </Typography>

            {/* Logo on the right */}
            <CardMedia
              component="img"
              height="60"
              sx={{
                objectFit: "contain",
                width: "60px",
                marginLeft: "10px",
              }}
              image={imageUrl}
              alt="company logo"
            />
          </Box>

          {/* Director and city details */}
          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
            {/* <Typography variant="body2" sx={{ color: "text.secondary" }}>
              <strong>Director:</strong> {directorName}
            </Typography> */}
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              <FontAwesomeIcon icon={faLocationDot} /> {city}
            </Typography>
          </Stack>

          {/* About section */}
          {/* <Typography variant="body2" sx={{ mt: 2, color: "text.secondary" }}>
           
            {overview}
          </Typography> */}

          <Typography
            variant="body2"
            sx={{
              mt: 2,
              color: "text.secondary",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {overview}
          </Typography>

          <Typography
            variant="body2"
            sx={{
              mt: 2,
              color: "text.secondary",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <strong>Services: </strong> {productAndServices}
          </Typography>

          {/* <Container> */}
          <Box
            sx={{
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
              color: "text.secondary",
            }}
          >
            <Typography variant="body2">
              {" "}
              <strong>Total Shares: </strong>
            </Typography>
            <Typography variant="body2" sx={{ ml: 1, color: "#808080" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {totalShares}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
              color: "text.secondary",
            }}
          >
            <Typography variant="body2">
              {" "}
              <strong>Per Share Value: </strong>
            </Typography>
            <Typography variant="body2" sx={{ ml: 1, color: "#808080" }}>
              ₹{perShareValue}
            </Typography>
          </Box>
          {/* </Container> */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
              color: "text.secondary",
            }}
          >
            <Typography variant="body2">
              IPO Year:
              <span style={{ color: "red" }}>
                &nbsp;&nbsp;&nbsp;
                {ipoYear}
              </span>
            </Typography>
            {/* <Link to={"/company"} state={id}>
              {name.toUpperCase() === "SPLICE LAMINATES" ||
                name.toUpperCase() === "MASKEEN TOYS" || name.toUpperCase()==="IJPN TECHNOLOGIES PRIVATE LIMITED" || name.toUpperCase()==="DRAAX FASHIONS PRIVATE LIMITED" || name.toUpperCase()==="BUBBLING RIVER PRIVATE LIMITED" ||name.toUpperCase()=== "BUBBLING RIVER PRIVATE LIMITED" || name.toUpperCase()==="HOME STAYS OF INDIA PRIVATE LIMITED"?  <Button className="company-btn">Upcoming</Button>: <Button className="company-btn">Contact Company</Button>}
            </Link> */}
            <Link to={"/company"} state={id}>
              {[
                "SPLICE LAMINATES",
                "MASKEEN TOYS",
                "IJPN TECHNOLOGIES PRIVATE LIMITED",
                "DRAAX FASHIONS PRIVATE LIMITED", 
                "BUBBLING RIVER PRIVATE LIMITED",
                "HOME STAYS OF INDIA PRIVATE LIMITED",
              ].includes(name.toUpperCase()) ? (
                <Button className="company-btn">Upcoming</Button>
              ) : (
                <Button className="company-btn">Contact Company</Button>
              )}
            </Link>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
