import React from 'react'

const EquityDesign = () => {
  return (
    <div style={{ padding: "16px", paddingBottom: "32px" }}>
        <div style={{ textAlign: "center", fontFamily: "Daikon-regular" }}>
          <h1>Process To Buy Private Equity</h1>
          <p>Recommended For Those Interested In Dealing With Private Equity</p>
        </div>
        <div className="square">
          <div className="left-line"></div>
          <div className="dot top-dot"></div>
          <div className="dot bottom-dot"></div>
          <div className="number number-top" style={{ fontWeight: "bold" }}>
            01
          </div>
          {/* <!-- <div className="number number-bottom">6</div> --> */}
          <div className="outer-circle"></div>
          <div className="left-text" style={{ fontFamily: "Daikon-regular" }}>
            At AQT Direct, we conduct thorough research and audits to provide
            our clients with valuable insights into potential investment
            opportunities. Our meticulous approach helps identify risks and
            opportunities, allowing investors to make informed decisions.
          </div>
          <div className="center-text" style={{ fontFamily: "Daikon-regular" }}>
            Research & Audit
          </div>
        </div>
        <br />

        <div className="square2">
          <div className="left-line2"></div>
          <div className="dot2 top-dot2"></div>
          <div className="dot2 bottom-dot2"></div>
          <div className="number2 number-top2" style={{ fontWeight: "bold" }}>
            02
          </div>
          <div className="outer-circle2"></div>
          <div className="left-text2" style={{ fontFamily: "Daikon-regular" }}>
            We ensure that our clients comply with all regulatory requirements
            and best practices when structuring equity deals. Our expertise in
            deal compliance minimizes legal and financial risks, safeguarding
            our clients' investments.
          </div>
          <div
            className="center-text2"
            style={{ fontFamily: "Daikon-regular" }}
          >
            Equity Deal Compliances
          </div>
        </div>

        <br />
        <div className="square">
          <div className="left-line"></div>
          <div className="dot top-dot"></div>
          <div className="dot bottom-dot"></div>
          <div className="number number-top" style={{ fontWeight: "bold" }}>
            03
          </div>
          {/* <div className="number number-bottom">6</div>  */}
          <div className="outer-circle"></div>
          <div className="left-text" style={{ fontFamily: "Daikon-regular" }}>
            {" "}
            AQT Direct specializes in creating value for our clients through
            strategic investment strategies and operational improvements. We
            work closely with management teams to unlock growth potential and
            enhance shareholder value.
          </div>
          <div className="center-text" style={{ fontFamily: "Daikon-regular" }}>
            Value Creation
          </div>
        </div>

        <br />
        <div className="square2">
          <div className="left-line2"></div>
          <div className="dot2 top-dot2"></div>
          <div className="dot2 bottom-dot2"></div>
          <div className="number2 number-top2" style={{ fontWeight: "bold" }}>
            04
          </div>
          <div className="outer-circle2"></div>
          <div className="left-text2" style={{ fontFamily: "Daikon-regular" }}>
            Planning an exit strategy is crucial for maximizing returns on
            investments. AQT Direct assists clients in developing and executing
            exit plans that align with their financial objectives and market
            conditions.
          </div>
          <div
            className="center-text2"
            style={{ fontFamily: "Daikon-regular" }}
          >
            Exit Strategist
          </div>
        </div>
      </div>
  )
}

export default EquityDesign